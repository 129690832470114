const lAudioContext =
    typeof AudioContext !== 'undefined'
        ? AudioContext
        : typeof webkitAudioContext !== 'undefined'
          ? webkitAudioContext
          : undefined;
let wasm;

function isLikeNone(x) {
    return x === undefined || x === null;
}

function addToExternrefTable0(obj) {
    const idx = wasm.__externref_table_alloc();
    wasm.__wbindgen_export_1.set(idx, obj);
    return idx;
}

const cachedTextDecoder =
    typeof TextDecoder !== 'undefined'
        ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true })
        : {
              decode: () => {
                  throw Error('TextDecoder not available');
              },
          };

if (typeof TextDecoder !== 'undefined') {
    cachedTextDecoder.decode();
}

let cachedUint8ArrayMemory0 = null;

function getUint8ArrayMemory0() {
    if (
        cachedUint8ArrayMemory0 === null ||
        cachedUint8ArrayMemory0.byteLength === 0
    ) {
        cachedUint8ArrayMemory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8ArrayMemory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(
        getUint8ArrayMemory0().subarray(ptr, ptr + len),
    );
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        const idx = addToExternrefTable0(e);
        wasm.__wbindgen_exn_store(idx);
    }
}

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder =
    typeof TextEncoder !== 'undefined'
        ? new TextEncoder('utf-8')
        : {
              encode: () => {
                  throw Error('TextEncoder not available');
              },
          };

const encodeString =
    typeof cachedTextEncoder.encodeInto === 'function'
        ? function (arg, view) {
              return cachedTextEncoder.encodeInto(arg, view);
          }
        : function (arg, view) {
              const buf = cachedTextEncoder.encode(arg);
              view.set(buf);
              return {
                  read: arg.length,
                  written: buf.length,
              };
          };

function passStringToWasm0(arg, malloc, realloc) {
    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8ArrayMemory0()
            .subarray(ptr, ptr + buf.length)
            .set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8ArrayMemory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7f) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, (len = offset + arg.length * 3), 1) >>> 0;
        const view = getUint8ArrayMemory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

let cachedDataViewMemory0 = null;

function getDataViewMemory0() {
    if (
        cachedDataViewMemory0 === null ||
        cachedDataViewMemory0.buffer.detached === true ||
        (cachedDataViewMemory0.buffer.detached === undefined &&
            cachedDataViewMemory0.buffer !== wasm.memory.buffer)
    ) {
        cachedDataViewMemory0 = new DataView(wasm.memory.buffer);
    }
    return cachedDataViewMemory0;
}

let cachedFloat32ArrayMemory0 = null;

function getFloat32ArrayMemory0() {
    if (
        cachedFloat32ArrayMemory0 === null ||
        cachedFloat32ArrayMemory0.byteLength === 0
    ) {
        cachedFloat32ArrayMemory0 = new Float32Array(wasm.memory.buffer);
    }
    return cachedFloat32ArrayMemory0;
}

function getArrayF32FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getFloat32ArrayMemory0().subarray(ptr / 4, ptr / 4 + len);
}

let cachedInt32ArrayMemory0 = null;

function getInt32ArrayMemory0() {
    if (
        cachedInt32ArrayMemory0 === null ||
        cachedInt32ArrayMemory0.byteLength === 0
    ) {
        cachedInt32ArrayMemory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32ArrayMemory0;
}

function getArrayI32FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getInt32ArrayMemory0().subarray(ptr / 4, ptr / 4 + len);
}

let cachedUint32ArrayMemory0 = null;

function getUint32ArrayMemory0() {
    if (
        cachedUint32ArrayMemory0 === null ||
        cachedUint32ArrayMemory0.byteLength === 0
    ) {
        cachedUint32ArrayMemory0 = new Uint32Array(wasm.memory.buffer);
    }
    return cachedUint32ArrayMemory0;
}

function getArrayU32FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getUint32ArrayMemory0().subarray(ptr / 4, ptr / 4 + len);
}

let cachedUint8ClampedArrayMemory0 = null;

function getUint8ClampedArrayMemory0() {
    if (
        cachedUint8ClampedArrayMemory0 === null ||
        cachedUint8ClampedArrayMemory0.byteLength === 0
    ) {
        cachedUint8ClampedArrayMemory0 = new Uint8ClampedArray(
            wasm.memory.buffer,
        );
    }
    return cachedUint8ClampedArrayMemory0;
}

function getClampedArrayU8FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getUint8ClampedArrayMemory0().subarray(ptr / 1, ptr / 1 + len);
}

const CLOSURE_DTORS =
    typeof FinalizationRegistry === 'undefined'
        ? { register: () => {}, unregister: () => {} }
        : new FinalizationRegistry((state) => {
              wasm.__wbindgen_export_6.get(state.dtor)(state.a, state.b);
          });

function makeMutClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        const a = state.a;
        state.a = 0;
        try {
            return f(a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_6.get(state.dtor)(a, state.b);
                CLOSURE_DTORS.unregister(state);
            } else {
                state.a = a;
            }
        }
    };
    real.original = state;
    CLOSURE_DTORS.register(real, state, state);
    return real;
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for (let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches && builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}

export function start() {
    wasm.start();
}

/**
 * @param {number} a
 * @param {number} b
 * @returns {string}
 */
export function add(a, b) {
    let deferred1_0;
    let deferred1_1;
    try {
        const ret = wasm.add(a, b);
        deferred1_0 = ret[0];
        deferred1_1 = ret[1];
        return getStringFromWasm0(ret[0], ret[1]);
    } finally {
        wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
    }
}

/**
 * @param {string} message
 */
export function hello(message) {
    const ptr0 = passStringToWasm0(
        message,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
    );
    const len0 = WASM_VECTOR_LEN;
    wasm.hello(ptr0, len0);
}

function passArray8ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 1, 1) >>> 0;
    getUint8ArrayMemory0().set(arg, ptr / 1);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}
/**
 * @param {Uint8Array} input
 * @returns {string}
 */
export function encode_base64(input) {
    let deferred2_0;
    let deferred2_1;
    try {
        const ptr0 = passArray8ToWasm0(input, wasm.__wbindgen_malloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.encode_base64(ptr0, len0);
        deferred2_0 = ret[0];
        deferred2_1 = ret[1];
        return getStringFromWasm0(ret[0], ret[1]);
    } finally {
        wasm.__wbindgen_free(deferred2_0, deferred2_1, 1);
    }
}

/**
 * @param {string} base64_string
 * @returns {any}
 */
export function decode_base64_to_bytes(base64_string) {
    const ptr0 = passStringToWasm0(
        base64_string,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
    );
    const len0 = WASM_VECTOR_LEN;
    const ret = wasm.decode_base64_to_bytes(ptr0, len0);
    return ret;
}

/**
 * @param {string} binary_string
 * @returns {Uint8Array | undefined}
 */
export function binary_string_to_bytes(binary_string) {
    const ptr0 = passStringToWasm0(
        binary_string,
        wasm.__wbindgen_malloc,
        wasm.__wbindgen_realloc,
    );
    const len0 = WASM_VECTOR_LEN;
    const ret = wasm.binary_string_to_bytes(ptr0, len0);
    return ret;
}

/**
 * @param {any} content
 * @returns {any}
 */
export function check_content_and_convert(content) {
    const ret = wasm.check_content_and_convert(content);
    return ret;
}

function __wbg_adapter_36(arg0, arg1, arg2) {
    wasm.closure1660_externref_shim(arg0, arg1, arg2);
}

function __wbg_adapter_49(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h94e9d0e19d29c5dd(
        arg0,
        arg1,
        isLikeNone(arg2) ? 0 : addToExternrefTable0(arg2),
    );
}

function __wbg_adapter_56(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h01a991790d29a2ec(
        arg0,
        arg1,
    );
}

function __wbg_adapter_59(arg0, arg1, arg2, arg3) {
    wasm.closure1677_externref_shim(arg0, arg1, arg2, arg3);
}

function __wbg_adapter_62(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__he4b0901b51a50d64(
        arg0,
        arg1,
    );
}

function __wbg_adapter_65(arg0, arg1, arg2) {
    wasm.closure56823_externref_shim(arg0, arg1, arg2);
}

const __wbindgen_enum_GamepadMappingType = ['', 'standard'];

const __wbindgen_enum_PremultiplyAlpha = ['none', 'premultiply', 'default'];

const __wbindgen_enum_ResizeObserverBoxOptions = [
    'border-box',
    'content-box',
    'device-pixel-content-box',
];

const __wbindgen_enum_VisibilityState = ['hidden', 'visible'];

async function __wbg_load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);
            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn(
                        '`WebAssembly.instantiateStreaming` failed because your server does not serve Wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n',
                        e,
                    );
                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);
    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };
        } else {
            return instance;
        }
    }
}

function __wbg_get_imports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbg_Window_02fa58b243352adf = function (arg0) {
        const ret = arg0.Window;
        return ret;
    };
    imports.wbg.__wbg_Window_14cc3c5ee5dce589 = function (arg0) {
        const ret = arg0.Window;
        return ret;
    };
    imports.wbg.__wbg_WorkerGlobalScope_7b9805991ec727df = function (arg0) {
        const ret = arg0.WorkerGlobalScope;
        return ret;
    };
    imports.wbg.__wbg_abort_775ef1d17fc65868 = function (arg0) {
        arg0.abort();
    };
    imports.wbg.__wbg_activeElement_367599fdfa7ad115 = function (arg0) {
        const ret = arg0.activeElement;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_activeTexture_0f19d8acfa0a14c2 = function (arg0, arg1) {
        arg0.activeTexture(arg1 >>> 0);
    };
    imports.wbg.__wbg_activeTexture_460f2e367e813fb0 = function (arg0, arg1) {
        arg0.activeTexture(arg1 >>> 0);
    };
    imports.wbg.__wbg_addEventListener_90e553fdce254421 = function () {
        return handleError(function (arg0, arg1, arg2, arg3) {
            arg0.addEventListener(getStringFromWasm0(arg1, arg2), arg3);
        }, arguments);
    };
    imports.wbg.__wbg_addListener_2982bb811b6385c5 = function () {
        return handleError(function (arg0, arg1) {
            arg0.addListener(arg1);
        }, arguments);
    };
    imports.wbg.__wbg_altKey_c33c03aed82e4275 = function (arg0) {
        const ret = arg0.altKey;
        return ret;
    };
    imports.wbg.__wbg_altKey_d7495666df921121 = function (arg0) {
        const ret = arg0.altKey;
        return ret;
    };
    imports.wbg.__wbg_animate_e8655261bbcba4d2 = function (arg0, arg1, arg2) {
        const ret = arg0.animate(arg1, arg2);
        return ret;
    };
    imports.wbg.__wbg_appendChild_8204974b7328bf98 = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg0.appendChild(arg1);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_arrayBuffer_d1b44c4390db422f = function () {
        return handleError(function (arg0) {
            const ret = arg0.arrayBuffer();
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_attachShader_3d4eb6af9e3e7bd1 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.attachShader(arg1, arg2);
    };
    imports.wbg.__wbg_attachShader_94e758c8b5283eb2 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.attachShader(arg1, arg2);
    };
    imports.wbg.__wbg_axes_b1da727bd9ea422d = function (arg0) {
        const ret = arg0.axes;
        return ret;
    };
    imports.wbg.__wbg_beginQuery_6af0b28414b16c07 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.beginQuery(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindAttribLocation_40da4b3e84cc7bd5 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.bindAttribLocation(
            arg1,
            arg2 >>> 0,
            getStringFromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_bindAttribLocation_ce2730e29976d230 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.bindAttribLocation(
            arg1,
            arg2 >>> 0,
            getStringFromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_bindBufferRange_454f90f2b1781982 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.bindBufferRange(arg1 >>> 0, arg2 >>> 0, arg3, arg4, arg5);
    };
    imports.wbg.__wbg_bindBuffer_309c9a6c21826cf5 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.bindBuffer(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindBuffer_f32f587f1c2962a7 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.bindBuffer(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindFramebuffer_bd02c8cc707d670f = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.bindFramebuffer(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindFramebuffer_e48e83c0f973944d = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.bindFramebuffer(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindRenderbuffer_53eedd88e52b4cb5 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.bindRenderbuffer(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindRenderbuffer_55e205fecfddbb8c = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.bindRenderbuffer(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindSampler_9f59cf2eaa22eee0 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.bindSampler(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindTexture_a6e795697f49ebd1 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.bindTexture(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindTexture_bc8eb316247f739d = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.bindTexture(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_bindVertexArrayOES_da8e7059b789629e = function (
        arg0,
        arg1,
    ) {
        arg0.bindVertexArrayOES(arg1);
    };
    imports.wbg.__wbg_bindVertexArray_6b4b88581064b71f = function (arg0, arg1) {
        arg0.bindVertexArray(arg1);
    };
    imports.wbg.__wbg_blendColor_15ba1eff44560932 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.blendColor(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_blendColor_6446fba673f64ff0 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.blendColor(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_blendEquationSeparate_c1aa26a9a5c5267e = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.blendEquationSeparate(arg1 >>> 0, arg2 >>> 0);
    };
    imports.wbg.__wbg_blendEquationSeparate_f3d422e981d86339 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.blendEquationSeparate(arg1 >>> 0, arg2 >>> 0);
    };
    imports.wbg.__wbg_blendEquation_c23d111ad6d268ff = function (arg0, arg1) {
        arg0.blendEquation(arg1 >>> 0);
    };
    imports.wbg.__wbg_blendEquation_cec7bc41f3e5704c = function (arg0, arg1) {
        arg0.blendEquation(arg1 >>> 0);
    };
    imports.wbg.__wbg_blendFuncSeparate_483be8d4dd635340 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.blendFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
    };
    imports.wbg.__wbg_blendFuncSeparate_dafeabfc1680b2ee = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.blendFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
    };
    imports.wbg.__wbg_blendFunc_9454884a3cfd2911 = function (arg0, arg1, arg2) {
        arg0.blendFunc(arg1 >>> 0, arg2 >>> 0);
    };
    imports.wbg.__wbg_blendFunc_c3b74be5a39c665f = function (arg0, arg1, arg2) {
        arg0.blendFunc(arg1 >>> 0, arg2 >>> 0);
    };
    imports.wbg.__wbg_blitFramebuffer_7303bdff77cfe967 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9,
        arg10,
    ) {
        arg0.blitFramebuffer(
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9 >>> 0,
            arg10 >>> 0,
        );
    };
    imports.wbg.__wbg_blockSize_1490803190b57a34 = function (arg0) {
        const ret = arg0.blockSize;
        return ret;
    };
    imports.wbg.__wbg_body_942ea927546a04ba = function (arg0) {
        const ret = arg0.body;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_brand_ea03eb348cfdee8f = function (arg0, arg1) {
        const ret = arg1.brand;
        const ptr1 = passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
        );
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_brands_e9fa822fae2acb51 = function (arg0) {
        const ret = arg0.brands;
        return ret;
    };
    imports.wbg.__wbg_bufferData_3261d3e1dd6fc903 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
    };
    imports.wbg.__wbg_bufferData_33c59bf909ea6fd3 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
    };
    imports.wbg.__wbg_bufferData_463178757784fcac = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
    };
    imports.wbg.__wbg_bufferData_d99b6b4eb5283f20 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.bufferData(arg1 >>> 0, arg2, arg3 >>> 0);
    };
    imports.wbg.__wbg_bufferSubData_4e973eefe9236d04 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.bufferSubData(arg1 >>> 0, arg2, arg3);
    };
    imports.wbg.__wbg_bufferSubData_dcd4d16031a60345 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.bufferSubData(arg1 >>> 0, arg2, arg3);
    };
    imports.wbg.__wbg_buffer_609cc3eee51ed158 = function (arg0) {
        const ret = arg0.buffer;
        return ret;
    };
    imports.wbg.__wbg_button_f75c56aec440ea04 = function (arg0) {
        const ret = arg0.button;
        return ret;
    };
    imports.wbg.__wbg_buttons_b6346af6f04e4686 = function (arg0) {
        const ret = arg0.buttons;
        return ret;
    };
    imports.wbg.__wbg_buttons_c4b2b5a0c27f0743 = function (arg0) {
        const ret = arg0.buttons;
        return ret;
    };
    imports.wbg.__wbg_call_672a4d21634d4a24 = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg0.call(arg1);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_call_7cccdd69e0791ae2 = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = arg0.call(arg1, arg2);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_cancelAnimationFrame_089b48301c362fde = function () {
        return handleError(function (arg0, arg1) {
            arg0.cancelAnimationFrame(arg1);
        }, arguments);
    };
    imports.wbg.__wbg_cancelIdleCallback_669eb1ed294c8b8b = function (
        arg0,
        arg1,
    ) {
        arg0.cancelIdleCallback(arg1 >>> 0);
    };
    imports.wbg.__wbg_cancel_e144af3144baffb2 = function (arg0) {
        arg0.cancel();
    };
    imports.wbg.__wbg_catch_a6e601879b2610e9 = function (arg0, arg1) {
        const ret = arg0.catch(arg1);
        return ret;
    };
    imports.wbg.__wbg_clearBufferfv_65ea413f7f2554a2 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.clearBufferfv(arg1 >>> 0, arg2, getArrayF32FromWasm0(arg3, arg4));
    };
    imports.wbg.__wbg_clearBufferiv_c003c27b77a0245b = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.clearBufferiv(arg1 >>> 0, arg2, getArrayI32FromWasm0(arg3, arg4));
    };
    imports.wbg.__wbg_clearBufferuiv_8c285072f2026a37 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.clearBufferuiv(arg1 >>> 0, arg2, getArrayU32FromWasm0(arg3, arg4));
    };
    imports.wbg.__wbg_clearDepth_17cfee5be8476fae = function (arg0, arg1) {
        arg0.clearDepth(arg1);
    };
    imports.wbg.__wbg_clearDepth_670d19914a501259 = function (arg0, arg1) {
        arg0.clearDepth(arg1);
    };
    imports.wbg.__wbg_clearStencil_4323424f1acca0df = function (arg0, arg1) {
        arg0.clearStencil(arg1);
    };
    imports.wbg.__wbg_clearStencil_7addd3b330b56b27 = function (arg0, arg1) {
        arg0.clearStencil(arg1);
    };
    imports.wbg.__wbg_clearTimeout_b2651b7485c58446 = function (arg0, arg1) {
        arg0.clearTimeout(arg1);
    };
    imports.wbg.__wbg_clear_62b9037b892f6988 = function (arg0, arg1) {
        arg0.clear(arg1 >>> 0);
    };
    imports.wbg.__wbg_clear_f8d5f3c348d37d95 = function (arg0, arg1) {
        arg0.clear(arg1 >>> 0);
    };
    imports.wbg.__wbg_clientWaitSync_6930890a42bd44c0 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        const ret = arg0.clientWaitSync(arg1, arg2 >>> 0, arg3 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_close_414b379454494b29 = function (arg0) {
        arg0.close();
    };
    imports.wbg.__wbg_close_5a97ef05b337f8ce = function () {
        return handleError(function (arg0) {
            const ret = arg0.close();
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_code_459c120478e1ab6e = function (arg0, arg1) {
        const ret = arg1.code;
        const ptr1 = passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
        );
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_colorMask_5e7c60b9c7a57a2e = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0);
    };
    imports.wbg.__wbg_colorMask_6dac12039c7145ae = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0);
    };
    imports.wbg.__wbg_compileShader_0ad770bbdbb9de21 = function (arg0, arg1) {
        arg0.compileShader(arg1);
    };
    imports.wbg.__wbg_compileShader_2307c9d370717dd5 = function (arg0, arg1) {
        arg0.compileShader(arg1);
    };
    imports.wbg.__wbg_compressedTexSubImage2D_71877eec950ca069 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9,
    ) {
        arg0.compressedTexSubImage2D(
            arg1 >>> 0,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7 >>> 0,
            arg8,
            arg9,
        );
    };
    imports.wbg.__wbg_compressedTexSubImage2D_99abf4cfdb7c3fd8 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
    ) {
        arg0.compressedTexSubImage2D(
            arg1 >>> 0,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7 >>> 0,
            arg8,
        );
    };
    imports.wbg.__wbg_compressedTexSubImage2D_d66dcfcb2422e703 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
    ) {
        arg0.compressedTexSubImage2D(
            arg1 >>> 0,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7 >>> 0,
            arg8,
        );
    };
    imports.wbg.__wbg_compressedTexSubImage3D_58506392da46b927 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9,
        arg10,
    ) {
        arg0.compressedTexSubImage3D(
            arg1 >>> 0,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9 >>> 0,
            arg10,
        );
    };
    imports.wbg.__wbg_compressedTexSubImage3D_81477746675a4017 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9,
        arg10,
        arg11,
    ) {
        arg0.compressedTexSubImage3D(
            arg1 >>> 0,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9 >>> 0,
            arg10,
            arg11,
        );
    };
    imports.wbg.__wbg_connect_b22945d106632a36 = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg0.connect(arg1);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_connected_18470c3ee82326ee = function (arg0) {
        const ret = arg0.connected;
        return ret;
    };
    imports.wbg.__wbg_contains_3361c7eda6c95afd = function (arg0, arg1) {
        const ret = arg0.contains(arg1);
        return ret;
    };
    imports.wbg.__wbg_contentRect_81407eb60e52248f = function (arg0) {
        const ret = arg0.contentRect;
        return ret;
    };
    imports.wbg.__wbg_copyBufferSubData_9469a965478e33b5 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.copyBufferSubData(arg1 >>> 0, arg2 >>> 0, arg3, arg4, arg5);
    };
    imports.wbg.__wbg_copyTexSubImage2D_05e7e8df6814a705 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
    ) {
        arg0.copyTexSubImage2D(
            arg1 >>> 0,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
        );
    };
    imports.wbg.__wbg_copyTexSubImage2D_607ad28606952982 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
    ) {
        arg0.copyTexSubImage2D(
            arg1 >>> 0,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
        );
    };
    imports.wbg.__wbg_copyTexSubImage3D_32e92c94044e58ca = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
        arg8,
        arg9,
    ) {
        arg0.copyTexSubImage3D(
            arg1 >>> 0,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        );
    };
    imports.wbg.__wbg_copyToChannel_b81ecf19fd54e146 = function () {
        return handleError(function (arg0, arg1, arg2, arg3) {
            arg0.copyToChannel(getArrayF32FromWasm0(arg1, arg2), arg3);
        }, arguments);
    };
    imports.wbg.__wbg_createBufferSource_f7860a96f709acbd = function () {
        return handleError(function (arg0) {
            const ret = arg0.createBufferSource();
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_createBuffer_7a9ec3d654073660 = function (arg0) {
        const ret = arg0.createBuffer();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createBuffer_926beeec3ff39b5a = function () {
        return handleError(function (arg0, arg1, arg2, arg3) {
            const ret = arg0.createBuffer(arg1 >>> 0, arg2 >>> 0, arg3);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_createBuffer_9886e84a67b68c89 = function (arg0) {
        const ret = arg0.createBuffer();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createElement_8c9931a732ee2fea = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = arg0.createElement(getStringFromWasm0(arg1, arg2));
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_createFramebuffer_7824f69bba778885 = function (arg0) {
        const ret = arg0.createFramebuffer();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createFramebuffer_c8d70ebc4858051e = function (arg0) {
        const ret = arg0.createFramebuffer();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createImageBitmap_eaa22bfb3a8626e8 = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = arg0.createImageBitmap(arg1, arg2);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_createObjectURL_6e98d2f9c7bd9764 = function () {
        return handleError(function (arg0, arg1) {
            const ret = URL.createObjectURL(arg1);
            const ptr1 = passStringToWasm0(
                ret,
                wasm.__wbindgen_malloc,
                wasm.__wbindgen_realloc,
            );
            const len1 = WASM_VECTOR_LEN;
            getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
            getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
        }, arguments);
    };
    imports.wbg.__wbg_createProgram_8ff56c485f3233d0 = function (arg0) {
        const ret = arg0.createProgram();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createProgram_da203074cafb1038 = function (arg0) {
        const ret = arg0.createProgram();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createQuery_5ed5e770ec1009c1 = function (arg0) {
        const ret = arg0.createQuery();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createRenderbuffer_d88aa9403faa38ea = function (arg0) {
        const ret = arg0.createRenderbuffer();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createRenderbuffer_fd347ae14f262eaa = function (arg0) {
        const ret = arg0.createRenderbuffer();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createSampler_f76e29d7522bec9e = function (arg0) {
        const ret = arg0.createSampler();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createShader_4a256a8cc9c1ce4f = function (arg0, arg1) {
        const ret = arg0.createShader(arg1 >>> 0);
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createShader_983150fb1243ee56 = function (arg0, arg1) {
        const ret = arg0.createShader(arg1 >>> 0);
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createTexture_9c536c79b635fdef = function (arg0) {
        const ret = arg0.createTexture();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createTexture_bfaa54c0cd22e367 = function (arg0) {
        const ret = arg0.createTexture();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createVertexArrayOES_991b44f100f93329 = function (arg0) {
        const ret = arg0.createVertexArrayOES();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_createVertexArray_e435029ae2660efd = function (arg0) {
        const ret = arg0.createVertexArray();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_crypto_ed58b8e10a292839 = function (arg0) {
        const ret = arg0.crypto;
        return ret;
    };
    imports.wbg.__wbg_ctrlKey_1e826e468105ac11 = function (arg0) {
        const ret = arg0.ctrlKey;
        return ret;
    };
    imports.wbg.__wbg_ctrlKey_cdbe8154dfb00d1f = function (arg0) {
        const ret = arg0.ctrlKey;
        return ret;
    };
    imports.wbg.__wbg_cullFace_187079e6e20a464d = function (arg0, arg1) {
        arg0.cullFace(arg1 >>> 0);
    };
    imports.wbg.__wbg_cullFace_fbae6dd4d5e61ba4 = function (arg0, arg1) {
        arg0.cullFace(arg1 >>> 0);
    };
    imports.wbg.__wbg_currentTime_adef4d803f58eb66 = function (arg0) {
        const ret = arg0.currentTime;
        return ret;
    };
    imports.wbg.__wbg_decode_3ad9767b863c0d8e = function (arg0) {
        const ret = arg0.decode();
        return ret;
    };
    imports.wbg.__wbg_deleteBuffer_7ed96e1bf7c02e87 = function (arg0, arg1) {
        arg0.deleteBuffer(arg1);
    };
    imports.wbg.__wbg_deleteBuffer_a7822433fc95dfb8 = function (arg0, arg1) {
        arg0.deleteBuffer(arg1);
    };
    imports.wbg.__wbg_deleteFramebuffer_66853fb7101488cb = function (
        arg0,
        arg1,
    ) {
        arg0.deleteFramebuffer(arg1);
    };
    imports.wbg.__wbg_deleteFramebuffer_cd3285ee5a702a7a = function (
        arg0,
        arg1,
    ) {
        arg0.deleteFramebuffer(arg1);
    };
    imports.wbg.__wbg_deleteProgram_3fa626bbc0001eb7 = function (arg0, arg1) {
        arg0.deleteProgram(arg1);
    };
    imports.wbg.__wbg_deleteProgram_71a133c6d053e272 = function (arg0, arg1) {
        arg0.deleteProgram(arg1);
    };
    imports.wbg.__wbg_deleteQuery_6a2b7cd30074b20b = function (arg0, arg1) {
        arg0.deleteQuery(arg1);
    };
    imports.wbg.__wbg_deleteRenderbuffer_59f4369653485031 = function (
        arg0,
        arg1,
    ) {
        arg0.deleteRenderbuffer(arg1);
    };
    imports.wbg.__wbg_deleteRenderbuffer_8808192853211567 = function (
        arg0,
        arg1,
    ) {
        arg0.deleteRenderbuffer(arg1);
    };
    imports.wbg.__wbg_deleteSampler_7f02bb003ba547f0 = function (arg0, arg1) {
        arg0.deleteSampler(arg1);
    };
    imports.wbg.__wbg_deleteShader_8d42f169deda58ac = function (arg0, arg1) {
        arg0.deleteShader(arg1);
    };
    imports.wbg.__wbg_deleteShader_c65a44796c5004d8 = function (arg0, arg1) {
        arg0.deleteShader(arg1);
    };
    imports.wbg.__wbg_deleteSync_5a3fbe5d6b742398 = function (arg0, arg1) {
        arg0.deleteSync(arg1);
    };
    imports.wbg.__wbg_deleteTexture_a30f5ca0163c4110 = function (arg0, arg1) {
        arg0.deleteTexture(arg1);
    };
    imports.wbg.__wbg_deleteTexture_bb82c9fec34372ba = function (arg0, arg1) {
        arg0.deleteTexture(arg1);
    };
    imports.wbg.__wbg_deleteVertexArrayOES_1ee7a06a4b23ec8c = function (
        arg0,
        arg1,
    ) {
        arg0.deleteVertexArrayOES(arg1);
    };
    imports.wbg.__wbg_deleteVertexArray_77fe73664a3332ae = function (
        arg0,
        arg1,
    ) {
        arg0.deleteVertexArray(arg1);
    };
    imports.wbg.__wbg_deltaMode_9bfd9fe3f6b4b240 = function (arg0) {
        const ret = arg0.deltaMode;
        return ret;
    };
    imports.wbg.__wbg_deltaX_5c1121715746e4b7 = function (arg0) {
        const ret = arg0.deltaX;
        return ret;
    };
    imports.wbg.__wbg_deltaY_f9318542caea0c36 = function (arg0) {
        const ret = arg0.deltaY;
        return ret;
    };
    imports.wbg.__wbg_depthFunc_2906916f4536d5d7 = function (arg0, arg1) {
        arg0.depthFunc(arg1 >>> 0);
    };
    imports.wbg.__wbg_depthFunc_f34449ae87cc4e3e = function (arg0, arg1) {
        arg0.depthFunc(arg1 >>> 0);
    };
    imports.wbg.__wbg_depthMask_5fe84e2801488eda = function (arg0, arg1) {
        arg0.depthMask(arg1 !== 0);
    };
    imports.wbg.__wbg_depthMask_76688a8638b2f321 = function (arg0, arg1) {
        arg0.depthMask(arg1 !== 0);
    };
    imports.wbg.__wbg_depthRange_3cd6b4dc961d9116 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.depthRange(arg1, arg2);
    };
    imports.wbg.__wbg_depthRange_f9c084ff3d81fd7b = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.depthRange(arg1, arg2);
    };
    imports.wbg.__wbg_destination_6400091abd6f01b3 = function (arg0) {
        const ret = arg0.destination;
        return ret;
    };
    imports.wbg.__wbg_devicePixelContentBoxSize_a6de82cb30d70825 = function (
        arg0,
    ) {
        const ret = arg0.devicePixelContentBoxSize;
        return ret;
    };
    imports.wbg.__wbg_devicePixelRatio_68c391265f05d093 = function (arg0) {
        const ret = arg0.devicePixelRatio;
        return ret;
    };
    imports.wbg.__wbg_disableVertexAttribArray_452cc9815fced7e4 = function (
        arg0,
        arg1,
    ) {
        arg0.disableVertexAttribArray(arg1 >>> 0);
    };
    imports.wbg.__wbg_disableVertexAttribArray_afd097fb465dc100 = function (
        arg0,
        arg1,
    ) {
        arg0.disableVertexAttribArray(arg1 >>> 0);
    };
    imports.wbg.__wbg_disable_2702df5b5da5dd21 = function (arg0, arg1) {
        arg0.disable(arg1 >>> 0);
    };
    imports.wbg.__wbg_disable_8b53998501a7a85b = function (arg0, arg1) {
        arg0.disable(arg1 >>> 0);
    };
    imports.wbg.__wbg_disconnect_2118016d75479985 = function (arg0) {
        arg0.disconnect();
    };
    imports.wbg.__wbg_disconnect_ac3f4ba550970c76 = function (arg0) {
        arg0.disconnect();
    };
    imports.wbg.__wbg_document_d249400bd7bd996d = function (arg0) {
        const ret = arg0.document;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_drawArraysInstancedANGLE_342ee6b5236d9702 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.drawArraysInstancedANGLE(arg1 >>> 0, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_drawArraysInstanced_622ea9f149b0b80c = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.drawArraysInstanced(arg1 >>> 0, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_drawArrays_6acaa2669c105f3a = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.drawArrays(arg1 >>> 0, arg2, arg3);
    };
    imports.wbg.__wbg_drawArrays_6d29ea2ebc0c72a2 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.drawArrays(arg1 >>> 0, arg2, arg3);
    };
    imports.wbg.__wbg_drawBuffersWEBGL_9fdbdf3d4cbd3aae = function (
        arg0,
        arg1,
    ) {
        arg0.drawBuffersWEBGL(arg1);
    };
    imports.wbg.__wbg_drawBuffers_e729b75c5a50d760 = function (arg0, arg1) {
        arg0.drawBuffers(arg1);
    };
    imports.wbg.__wbg_drawElementsInstancedANGLE_096b48ab8686c5cf = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.drawElementsInstancedANGLE(
            arg1 >>> 0,
            arg2,
            arg3 >>> 0,
            arg4,
            arg5,
        );
    };
    imports.wbg.__wbg_drawElementsInstanced_f874e87d0b4e95e9 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.drawElementsInstanced(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
    };
    imports.wbg.__wbg_enableVertexAttribArray_607be07574298e5e = function (
        arg0,
        arg1,
    ) {
        arg0.enableVertexAttribArray(arg1 >>> 0);
    };
    imports.wbg.__wbg_enableVertexAttribArray_93c3d406a41ad6c7 = function (
        arg0,
        arg1,
    ) {
        arg0.enableVertexAttribArray(arg1 >>> 0);
    };
    imports.wbg.__wbg_enable_51114837e05ee280 = function (arg0, arg1) {
        arg0.enable(arg1 >>> 0);
    };
    imports.wbg.__wbg_enable_d183fef39258803f = function (arg0, arg1) {
        arg0.enable(arg1 >>> 0);
    };
    imports.wbg.__wbg_endQuery_17aac36532ca7d47 = function (arg0, arg1) {
        arg0.endQuery(arg1 >>> 0);
    };
    imports.wbg.__wbg_error_1004b8c64097413f = function (arg0, arg1) {
        console.error(arg0, arg1);
    };
    imports.wbg.__wbg_error_7534b8e9a36f1ab4 = function (arg0, arg1) {
        let deferred0_0;
        let deferred0_1;
        try {
            deferred0_0 = arg0;
            deferred0_1 = arg1;
            console.error(getStringFromWasm0(arg0, arg1));
        } finally {
            wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
        }
    };
    imports.wbg.__wbg_eval_e10dc02e9547f640 = function () {
        return handleError(function (arg0, arg1) {
            const ret = eval(getStringFromWasm0(arg0, arg1));
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_exec_3e2d2d0644c927df = function (arg0, arg1, arg2) {
        const ret = arg0.exec(getStringFromWasm0(arg1, arg2));
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_exitFullscreen_909f35c20d9db949 = function (arg0) {
        arg0.exitFullscreen();
    };
    imports.wbg.__wbg_exitPointerLock_7b6e7252c7133f69 = function (arg0) {
        arg0.exitPointerLock();
    };
    imports.wbg.__wbg_fenceSync_02d142d21e315da6 = function (arg0, arg1, arg2) {
        const ret = arg0.fenceSync(arg1 >>> 0, arg2 >>> 0);
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_fetch_1b7e793ab8320753 = function (arg0, arg1, arg2) {
        const ret = arg0.fetch(getStringFromWasm0(arg1, arg2));
        return ret;
    };
    imports.wbg.__wbg_fetch_6fed924f7801d8f3 = function (arg0, arg1, arg2) {
        const ret = arg0.fetch(getStringFromWasm0(arg1, arg2));
        return ret;
    };
    imports.wbg.__wbg_focus_7d08b55eba7b368d = function () {
        return handleError(function (arg0) {
            arg0.focus();
        }, arguments);
    };
    imports.wbg.__wbg_framebufferRenderbuffer_2fdd12e89ad81eb9 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.framebufferRenderbuffer(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4);
    };
    imports.wbg.__wbg_framebufferRenderbuffer_8b88592753b54715 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.framebufferRenderbuffer(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4);
    };
    imports.wbg.__wbg_framebufferTexture2D_81a565732bd5d8fe = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.framebufferTexture2D(
            arg1 >>> 0,
            arg2 >>> 0,
            arg3 >>> 0,
            arg4,
            arg5,
        );
    };
    imports.wbg.__wbg_framebufferTexture2D_ed855d0b097c557a = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.framebufferTexture2D(
            arg1 >>> 0,
            arg2 >>> 0,
            arg3 >>> 0,
            arg4,
            arg5,
        );
    };
    imports.wbg.__wbg_framebufferTextureLayer_5e6bd1b0cb45d815 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.framebufferTextureLayer(arg1 >>> 0, arg2 >>> 0, arg3, arg4, arg5);
    };
    imports.wbg.__wbg_framebufferTextureMultiviewOVR_e54f936c3cc382cb =
        function (arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
            arg0.framebufferTextureMultiviewOVR(
                arg1 >>> 0,
                arg2 >>> 0,
                arg3,
                arg4,
                arg5,
                arg6,
            );
        };
    imports.wbg.__wbg_frontFace_289c9d7a8569c4f2 = function (arg0, arg1) {
        arg0.frontFace(arg1 >>> 0);
    };
    imports.wbg.__wbg_frontFace_4d4936cfaeb8b7df = function (arg0, arg1) {
        arg0.frontFace(arg1 >>> 0);
    };
    imports.wbg.__wbg_fullscreenElement_a2f691b04c3b3de5 = function (arg0) {
        const ret = arg0.fullscreenElement;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_getBoundingClientRect_9073b0ff7574d76b = function (arg0) {
        const ret = arg0.getBoundingClientRect();
        return ret;
    };
    imports.wbg.__wbg_getBufferSubData_8ab2dcc5fcf5770f = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.getBufferSubData(arg1 >>> 0, arg2, arg3);
    };
    imports.wbg.__wbg_getCoalescedEvents_a7d49de30111f6b8 = function (arg0) {
        const ret = arg0.getCoalescedEvents();
        return ret;
    };
    imports.wbg.__wbg_getCoalescedEvents_ad74da26d81a76af = function (arg0) {
        const ret = arg0.getCoalescedEvents;
        return ret;
    };
    imports.wbg.__wbg_getComputedStyle_046dd6472f8e7f1d = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg0.getComputedStyle(arg1);
            return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
        }, arguments);
    };
    imports.wbg.__wbg_getContext_3ae09aaa73194801 = function () {
        return handleError(function (arg0, arg1, arg2, arg3) {
            const ret = arg0.getContext(getStringFromWasm0(arg1, arg2), arg3);
            return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
        }, arguments);
    };
    imports.wbg.__wbg_getContext_e9cf379449413580 = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = arg0.getContext(getStringFromWasm0(arg1, arg2));
            return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
        }, arguments);
    };
    imports.wbg.__wbg_getContext_fc19859df6331073 = function () {
        return handleError(function (arg0, arg1, arg2, arg3) {
            const ret = arg0.getContext(getStringFromWasm0(arg1, arg2), arg3);
            return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
        }, arguments);
    };
    imports.wbg.__wbg_getExtension_ff0fb1398bcf28c3 = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = arg0.getExtension(getStringFromWasm0(arg1, arg2));
            return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
        }, arguments);
    };
    imports.wbg.__wbg_getGamepads_1f997cef580c9088 = function () {
        return handleError(function (arg0) {
            const ret = arg0.getGamepads();
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_getIndexedParameter_f9211edc36533919 = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = arg0.getIndexedParameter(arg1 >>> 0, arg2 >>> 0);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_getOwnPropertyDescriptor_9dd936a3c0cbd368 = function (
        arg0,
        arg1,
    ) {
        const ret = Object.getOwnPropertyDescriptor(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbg_getParameter_1f0887a2b88e6d19 = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg0.getParameter(arg1 >>> 0);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_getParameter_e3429f024018310f = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg0.getParameter(arg1 >>> 0);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_getProgramInfoLog_631c180b1b21c8ed = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg1.getProgramInfoLog(arg2);
        var ptr1 = isLikeNone(ret)
            ? 0
            : passStringToWasm0(
                  ret,
                  wasm.__wbindgen_malloc,
                  wasm.__wbindgen_realloc,
              );
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_getProgramInfoLog_a998105a680059db = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg1.getProgramInfoLog(arg2);
        var ptr1 = isLikeNone(ret)
            ? 0
            : passStringToWasm0(
                  ret,
                  wasm.__wbindgen_malloc,
                  wasm.__wbindgen_realloc,
              );
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_getProgramParameter_0c411f0cd4185c5b = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg0.getProgramParameter(arg1, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_getProgramParameter_360f95ff07ac068d = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg0.getProgramParameter(arg1, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_getPropertyValue_e623c23a05dfb30c = function () {
        return handleError(function (arg0, arg1, arg2, arg3) {
            const ret = arg1.getPropertyValue(getStringFromWasm0(arg2, arg3));
            const ptr1 = passStringToWasm0(
                ret,
                wasm.__wbindgen_malloc,
                wasm.__wbindgen_realloc,
            );
            const len1 = WASM_VECTOR_LEN;
            getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
            getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
        }, arguments);
    };
    imports.wbg.__wbg_getQueryParameter_8921497e1d1561c1 = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg0.getQueryParameter(arg1, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_getRandomValues_bcb4912f16000dc4 = function () {
        return handleError(function (arg0, arg1) {
            arg0.getRandomValues(arg1);
        }, arguments);
    };
    imports.wbg.__wbg_getShaderInfoLog_7e7b38fb910ec534 = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg1.getShaderInfoLog(arg2);
        var ptr1 = isLikeNone(ret)
            ? 0
            : passStringToWasm0(
                  ret,
                  wasm.__wbindgen_malloc,
                  wasm.__wbindgen_realloc,
              );
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_getShaderInfoLog_f59c3112acc6e039 = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg1.getShaderInfoLog(arg2);
        var ptr1 = isLikeNone(ret)
            ? 0
            : passStringToWasm0(
                  ret,
                  wasm.__wbindgen_malloc,
                  wasm.__wbindgen_realloc,
              );
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_getShaderParameter_511b5f929074fa31 = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg0.getShaderParameter(arg1, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_getShaderParameter_6dbe0b8558dc41fd = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg0.getShaderParameter(arg1, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_getSupportedExtensions_8c007dbb54905635 = function (
        arg0,
    ) {
        const ret = arg0.getSupportedExtensions();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_getSupportedProfiles_10d2a4d32a128384 = function (arg0) {
        const ret = arg0.getSupportedProfiles();
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_getSyncParameter_7cb8461f5891606c = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = arg0.getSyncParameter(arg1, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_getUniformBlockIndex_288fdc31528171ca = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        const ret = arg0.getUniformBlockIndex(
            arg1,
            getStringFromWasm0(arg2, arg3),
        );
        return ret;
    };
    imports.wbg.__wbg_getUniformLocation_657a2b6d102bd126 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        const ret = arg0.getUniformLocation(
            arg1,
            getStringFromWasm0(arg2, arg3),
        );
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_getUniformLocation_838363001c74dc21 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        const ret = arg0.getUniformLocation(
            arg1,
            getStringFromWasm0(arg2, arg3),
        );
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_get_b9b93047fe3cf45b = function (arg0, arg1) {
        const ret = arg0[arg1 >>> 0];
        return ret;
    };
    imports.wbg.__wbg_height_1d93eb7f5e355d97 = function (arg0) {
        const ret = arg0.height;
        return ret;
    };
    imports.wbg.__wbg_height_1f8226c8f6875110 = function (arg0) {
        const ret = arg0.height;
        return ret;
    };
    imports.wbg.__wbg_height_838cee19ba8597db = function (arg0) {
        const ret = arg0.height;
        return ret;
    };
    imports.wbg.__wbg_height_d3f39e12f0f62121 = function (arg0) {
        const ret = arg0.height;
        return ret;
    };
    imports.wbg.__wbg_height_df1aa98dfbbe11ad = function (arg0) {
        const ret = arg0.height;
        return ret;
    };
    imports.wbg.__wbg_height_e3c322f23d99ad2f = function (arg0) {
        const ret = arg0.height;
        return ret;
    };
    imports.wbg.__wbg_id_290cc18d62a31d87 = function (arg0, arg1) {
        const ret = arg1.id;
        const ptr1 = passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
        );
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_includes_937486a108ec147b = function (arg0, arg1, arg2) {
        const ret = arg0.includes(arg1, arg2);
        return ret;
    };
    imports.wbg.__wbg_index_ff9a331b48fca2da = function (arg0) {
        const ret = arg0.index;
        return ret;
    };
    imports.wbg.__wbg_inlineSize_8ff96b3ec1b24423 = function (arg0) {
        const ret = arg0.inlineSize;
        return ret;
    };
    imports.wbg.__wbg_instanceof_DomException_ed1ccb7aaf39034c = function (
        arg0,
    ) {
        let result;
        try {
            result = arg0 instanceof DOMException;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_HtmlCanvasElement_2ea67072a7624ac5 = function (
        arg0,
    ) {
        let result;
        try {
            result = arg0 instanceof HTMLCanvasElement;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_Response_f2cc20d9f7dfd644 = function (arg0) {
        let result;
        try {
            result = arg0 instanceof Response;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_Uint8Array_17156bcf118086a9 = function (arg0) {
        let result;
        try {
            result = arg0 instanceof Uint8Array;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_WebGl2RenderingContext_2b6045efeb76568d =
        function (arg0) {
            let result;
            try {
                result = arg0 instanceof WebGL2RenderingContext;
            } catch (_) {
                result = false;
            }
            const ret = result;
            return ret;
        };
    imports.wbg.__wbg_instanceof_Window_def73ea0955fc569 = function (arg0) {
        let result;
        try {
            result = arg0 instanceof Window;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_invalidateFramebuffer_83f643d2a4936456 = function () {
        return handleError(function (arg0, arg1, arg2) {
            arg0.invalidateFramebuffer(arg1 >>> 0, arg2);
        }, arguments);
    };
    imports.wbg.__wbg_isIntersecting_e68706dac9c5f2e9 = function (arg0) {
        const ret = arg0.isIntersecting;
        return ret;
    };
    imports.wbg.__wbg_isSecureContext_aedcf3816338189a = function (arg0) {
        const ret = arg0.isSecureContext;
        return ret;
    };
    imports.wbg.__wbg_is_c7481c65e7e5df9e = function (arg0, arg1) {
        const ret = Object.is(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbg_key_7b5c6cb539be8e13 = function (arg0, arg1) {
        const ret = arg1.key;
        const ptr1 = passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
        );
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_length_a446193dc22c12f8 = function (arg0) {
        const ret = arg0.length;
        return ret;
    };
    imports.wbg.__wbg_length_e2d2a49132c1b256 = function (arg0) {
        const ret = arg0.length;
        return ret;
    };
    imports.wbg.__wbg_linkProgram_067ee06739bdde81 = function (arg0, arg1) {
        arg0.linkProgram(arg1);
    };
    imports.wbg.__wbg_linkProgram_e002979fe36e5b2a = function (arg0, arg1) {
        arg0.linkProgram(arg1);
    };
    imports.wbg.__wbg_location_9b435486be8f98c2 = function (arg0) {
        const ret = arg0.location;
        return ret;
    };
    imports.wbg.__wbg_log_0cc1b7768397bcfe = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
        arg7,
    ) {
        let deferred0_0;
        let deferred0_1;
        try {
            deferred0_0 = arg0;
            deferred0_1 = arg1;
            console.log(
                getStringFromWasm0(arg0, arg1),
                getStringFromWasm0(arg2, arg3),
                getStringFromWasm0(arg4, arg5),
                getStringFromWasm0(arg6, arg7),
            );
        } finally {
            wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
        }
    };
    imports.wbg.__wbg_log_c222819a41e063d3 = function (arg0) {
        console.log(arg0);
    };
    imports.wbg.__wbg_log_cb9e190acc5753fb = function (arg0, arg1) {
        let deferred0_0;
        let deferred0_1;
        try {
            deferred0_0 = arg0;
            deferred0_1 = arg1;
            console.log(getStringFromWasm0(arg0, arg1));
        } finally {
            wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
        }
    };
    imports.wbg.__wbg_mapping_4346bd44a2f56d13 = function (arg0) {
        const ret = arg0.mapping;
        return (__wbindgen_enum_GamepadMappingType.indexOf(ret) + 1 || 3) - 1;
    };
    imports.wbg.__wbg_mark_7438147ce31e9d4b = function (arg0, arg1) {
        performance.mark(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbg_matchMedia_bf8807a841d930c1 = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = arg0.matchMedia(getStringFromWasm0(arg1, arg2));
            return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
        }, arguments);
    };
    imports.wbg.__wbg_matches_e9ca73fbf8a3a104 = function (arg0) {
        const ret = arg0.matches;
        return ret;
    };
    imports.wbg.__wbg_maxChannelCount_a06f8ca4190698ed = function (arg0) {
        const ret = arg0.maxChannelCount;
        return ret;
    };
    imports.wbg.__wbg_measure_fb7825c11612c823 = function () {
        return handleError(function (arg0, arg1, arg2, arg3) {
            let deferred0_0;
            let deferred0_1;
            let deferred1_0;
            let deferred1_1;
            try {
                deferred0_0 = arg0;
                deferred0_1 = arg1;
                deferred1_0 = arg2;
                deferred1_1 = arg3;
                performance.measure(
                    getStringFromWasm0(arg0, arg1),
                    getStringFromWasm0(arg2, arg3),
                );
            } finally {
                wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
                wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
            }
        }, arguments);
    };
    imports.wbg.__wbg_media_552eec81313ef78b = function (arg0, arg1) {
        const ret = arg1.media;
        const ptr1 = passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
        );
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_message_5c5d919204d42400 = function (arg0, arg1) {
        const ret = arg1.message;
        const ptr1 = passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
        );
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_metaKey_0b25f7848e014cc8 = function (arg0) {
        const ret = arg0.metaKey;
        return ret;
    };
    imports.wbg.__wbg_metaKey_e1dd47d709a80ce5 = function (arg0) {
        const ret = arg0.metaKey;
        return ret;
    };
    imports.wbg.__wbg_movementX_1aa05f864931369b = function (arg0) {
        const ret = arg0.movementX;
        return ret;
    };
    imports.wbg.__wbg_movementY_8acfedb38a70e624 = function (arg0) {
        const ret = arg0.movementY;
        return ret;
    };
    imports.wbg.__wbg_msCrypto_0a36e2ec3a343d26 = function (arg0) {
        const ret = arg0.msCrypto;
        return ret;
    };
    imports.wbg.__wbg_navigator_1577371c070c8947 = function (arg0) {
        const ret = arg0.navigator;
        return ret;
    };
    imports.wbg.__wbg_new_18b1151f3a6a9280 = function () {
        return handleError(function (arg0) {
            const ret = new IntersectionObserver(arg0);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_new_24b2c5b645cded8d = function () {
        return handleError(function () {
            const ret = new MessageChannel();
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_new_405e22f390576ce2 = function () {
        const ret = new Object();
        return ret;
    };
    imports.wbg.__wbg_new_5f34cc0c99fcc488 = function () {
        return handleError(function (arg0) {
            const ret = new ResizeObserver(arg0);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_new_6377da097a44ce6e = function () {
        return handleError(function () {
            const ret = new Image();
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_new_63847613cde5d4bc = function (arg0, arg1, arg2, arg3) {
        const ret = new RegExp(
            getStringFromWasm0(arg0, arg1),
            getStringFromWasm0(arg2, arg3),
        );
        return ret;
    };
    imports.wbg.__wbg_new_78feb108b6472713 = function () {
        const ret = new Array();
        return ret;
    };
    imports.wbg.__wbg_new_8a6f238a6ece86ea = function () {
        const ret = new Error();
        return ret;
    };
    imports.wbg.__wbg_new_a12002a7f91c75be = function (arg0) {
        const ret = new Uint8Array(arg0);
        return ret;
    };
    imports.wbg.__wbg_new_b1a33e5095abf678 = function () {
        return handleError(function (arg0, arg1) {
            const ret = new Worker(getStringFromWasm0(arg0, arg1));
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_new_e25e5aab09ff45db = function () {
        return handleError(function () {
            const ret = new AbortController();
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_newnoargs_105ed471475aaf50 = function (arg0, arg1) {
        const ret = new Function(getStringFromWasm0(arg0, arg1));
        return ret;
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_840f3c038856d4e9 = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = new Int8Array(arg0, arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_999332a180064b59 = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = new Int32Array(arg0, arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_d4a86622320ea258 = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = new Uint16Array(arg0, arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_d97e637ebe145a9a = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = new Uint8Array(arg0, arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_e6b7e69acd4c7354 = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = new Float32Array(arg0, arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_f1dead44d1fc7212 = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = new Uint32Array(arg0, arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_f254047f7e80e7ff = function (
        arg0,
        arg1,
        arg2,
    ) {
        const ret = new Int16Array(arg0, arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithcontextoptions_b62c06fed7900366 = function () {
        return handleError(function (arg0) {
            const ret = new lAudioContext(arg0);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_newwithlength_a381634e90c276d4 = function (arg0) {
        const ret = new Uint8Array(arg0 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithstrsequenceandoptions_aaff55b467c81b63 =
        function () {
            return handleError(function (arg0, arg1) {
                const ret = new Blob(arg0, arg1);
                return ret;
            }, arguments);
        };
    imports.wbg.__wbg_newwithu8clampedarray_0fcf78a036c89a97 = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = new ImageData(
                getClampedArrayU8FromWasm0(arg0, arg1),
                arg2 >>> 0,
            );
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_node_02999533c4ea02e3 = function (arg0) {
        const ret = arg0.node;
        return ret;
    };
    imports.wbg.__wbg_now_2c95c9de01293173 = function (arg0) {
        const ret = arg0.now();
        return ret;
    };
    imports.wbg.__wbg_now_807e54c39636c349 = function () {
        const ret = Date.now();
        return ret;
    };
    imports.wbg.__wbg_observe_d2e7378f15f7ca72 = function (arg0, arg1) {
        arg0.observe(arg1);
    };
    imports.wbg.__wbg_observe_eafddfc5a0c60e02 = function (arg0, arg1) {
        arg0.observe(arg1);
    };
    imports.wbg.__wbg_observe_ed4adb1c245103c5 = function (arg0, arg1, arg2) {
        arg0.observe(arg1, arg2);
    };
    imports.wbg.__wbg_of_2eaf5a02d443ef03 = function (arg0) {
        const ret = Array.of(arg0);
        return ret;
    };
    imports.wbg.__wbg_of_66b3ee656cbd962b = function (arg0, arg1) {
        const ret = Array.of(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbg_offsetX_989d29ed9335c7d0 = function (arg0) {
        const ret = arg0.offsetX;
        return ret;
    };
    imports.wbg.__wbg_offsetY_88f270098a180ce3 = function (arg0) {
        const ret = arg0.offsetY;
        return ret;
    };
    imports.wbg.__wbg_performance_7a3ffd0b17f663ad = function (arg0) {
        const ret = arg0.performance;
        return ret;
    };
    imports.wbg.__wbg_persisted_d32ce73b8e522062 = function (arg0) {
        const ret = arg0.persisted;
        return ret;
    };
    imports.wbg.__wbg_pixelStorei_6aba5d04cdcaeaf6 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.pixelStorei(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_pixelStorei_c8520e4b46f4a973 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.pixelStorei(arg1 >>> 0, arg2);
    };
    imports.wbg.__wbg_play_d475dddcc8433e39 = function (arg0) {
        arg0.play();
    };
    imports.wbg.__wbg_pointerId_585e63ee80a49927 = function (arg0) {
        const ret = arg0.pointerId;
        return ret;
    };
    imports.wbg.__wbg_pointerType_6bd934aa20d9db49 = function (arg0, arg1) {
        const ret = arg1.pointerType;
        const ptr1 = passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
        );
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_polygonOffset_773fe0017b2c8f51 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.polygonOffset(arg1, arg2);
    };
    imports.wbg.__wbg_polygonOffset_8c11c066486216c4 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.polygonOffset(arg1, arg2);
    };
    imports.wbg.__wbg_port1_70af0ea6e4a96f9d = function (arg0) {
        const ret = arg0.port1;
        return ret;
    };
    imports.wbg.__wbg_port2_0584c7f0938b6fe6 = function (arg0) {
        const ret = arg0.port2;
        return ret;
    };
    imports.wbg.__wbg_postMessage_e55d059efb191dc5 = function () {
        return handleError(function (arg0, arg1) {
            arg0.postMessage(arg1);
        }, arguments);
    };
    imports.wbg.__wbg_postMessage_f961e53b9731ca83 = function () {
        return handleError(function (arg0, arg1, arg2) {
            arg0.postMessage(arg1, arg2);
        }, arguments);
    };
    imports.wbg.__wbg_postTask_d51b4581f8f06154 = function (arg0, arg1, arg2) {
        const ret = arg0.postTask(arg1, arg2);
        return ret;
    };
    imports.wbg.__wbg_pressed_b3de5f0878ceb06e = function (arg0) {
        const ret = arg0.pressed;
        return ret;
    };
    imports.wbg.__wbg_pressure_adda5a83a9cec94d = function (arg0) {
        const ret = arg0.pressure;
        return ret;
    };
    imports.wbg.__wbg_preventDefault_c2314fd813c02b3c = function (arg0) {
        arg0.preventDefault();
    };
    imports.wbg.__wbg_process_5c1d670bc53614b8 = function (arg0) {
        const ret = arg0.process;
        return ret;
    };
    imports.wbg.__wbg_prototype_52a6e43569c41398 = function () {
        const ret = ResizeObserverEntry.prototype;
        return ret;
    };
    imports.wbg.__wbg_push_737cfc8c1432c2c6 = function (arg0, arg1) {
        const ret = arg0.push(arg1);
        return ret;
    };
    imports.wbg.__wbg_queryCounterEXT_7aed85645b7ec1da = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.queryCounterEXT(arg1, arg2 >>> 0);
    };
    imports.wbg.__wbg_querySelector_c69f8b573958906b = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = arg0.querySelector(getStringFromWasm0(arg1, arg2));
            return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
        }, arguments);
    };
    imports.wbg.__wbg_queueMicrotask_65a6c48ee9790d40 = function (arg0, arg1) {
        arg0.queueMicrotask(arg1);
    };
    imports.wbg.__wbg_queueMicrotask_97d92b4fcc8a61c5 = function (arg0) {
        queueMicrotask(arg0);
    };
    imports.wbg.__wbg_queueMicrotask_d3219def82552485 = function (arg0) {
        const ret = arg0.queueMicrotask;
        return ret;
    };
    imports.wbg.__wbg_randomFillSync_ab2cfe79ebbf2740 = function () {
        return handleError(function (arg0, arg1) {
            arg0.randomFillSync(arg1);
        }, arguments);
    };
    imports.wbg.__wbg_readBuffer_1c35b1e4939f881d = function (arg0, arg1) {
        arg0.readBuffer(arg1 >>> 0);
    };
    imports.wbg.__wbg_readPixels_51a0c02cdee207a5 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
        ) {
            arg0.readPixels(
                arg1,
                arg2,
                arg3,
                arg4,
                arg5 >>> 0,
                arg6 >>> 0,
                arg7,
            );
        }, arguments);
    };
    imports.wbg.__wbg_readPixels_a6cbb21794452142 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
        ) {
            arg0.readPixels(
                arg1,
                arg2,
                arg3,
                arg4,
                arg5 >>> 0,
                arg6 >>> 0,
                arg7,
            );
        }, arguments);
    };
    imports.wbg.__wbg_readPixels_cd64c5a7b0343355 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
        ) {
            arg0.readPixels(
                arg1,
                arg2,
                arg3,
                arg4,
                arg5 >>> 0,
                arg6 >>> 0,
                arg7,
            );
        }, arguments);
    };
    imports.wbg.__wbg_removeEventListener_056dfe8c3d6c58f9 = function () {
        return handleError(function (arg0, arg1, arg2, arg3) {
            arg0.removeEventListener(getStringFromWasm0(arg1, arg2), arg3);
        }, arguments);
    };
    imports.wbg.__wbg_removeListener_e55db581b73ccf65 = function () {
        return handleError(function (arg0, arg1) {
            arg0.removeListener(arg1);
        }, arguments);
    };
    imports.wbg.__wbg_removeProperty_0e85471f4dfc00ae = function () {
        return handleError(function (arg0, arg1, arg2, arg3) {
            const ret = arg1.removeProperty(getStringFromWasm0(arg2, arg3));
            const ptr1 = passStringToWasm0(
                ret,
                wasm.__wbindgen_malloc,
                wasm.__wbindgen_realloc,
            );
            const len1 = WASM_VECTOR_LEN;
            getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
            getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
        }, arguments);
    };
    imports.wbg.__wbg_renderbufferStorageMultisample_13fbd5e58900c6fe =
        function (arg0, arg1, arg2, arg3, arg4, arg5) {
            arg0.renderbufferStorageMultisample(
                arg1 >>> 0,
                arg2,
                arg3 >>> 0,
                arg4,
                arg5,
            );
        };
    imports.wbg.__wbg_renderbufferStorage_73e01ea83b8afab4 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.renderbufferStorage(arg1 >>> 0, arg2 >>> 0, arg3, arg4);
    };
    imports.wbg.__wbg_renderbufferStorage_f010012bd3566942 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.renderbufferStorage(arg1 >>> 0, arg2 >>> 0, arg3, arg4);
    };
    imports.wbg.__wbg_repeat_1882aa0d0072c705 = function (arg0) {
        const ret = arg0.repeat;
        return ret;
    };
    imports.wbg.__wbg_requestAnimationFrame_d7fd890aaefc3246 = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg0.requestAnimationFrame(arg1);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_requestFullscreen_900816ce59bc23ee = function (arg0) {
        const ret = arg0.requestFullscreen;
        return ret;
    };
    imports.wbg.__wbg_requestFullscreen_cb251917d11c059d = function (arg0) {
        const ret = arg0.requestFullscreen();
        return ret;
    };
    imports.wbg.__wbg_requestIdleCallback_67a4ae09ea470b9f = function (arg0) {
        const ret = arg0.requestIdleCallback;
        return ret;
    };
    imports.wbg.__wbg_requestIdleCallback_e3eefd34962470e1 = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg0.requestIdleCallback(arg1);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_requestPointerLock_304dd9ccfe548767 = function (arg0) {
        arg0.requestPointerLock();
    };
    imports.wbg.__wbg_require_79b1e9274cde3c87 = function () {
        return handleError(function () {
            const ret = module.require;
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_resolve_4851785c9c5f573d = function (arg0) {
        const ret = Promise.resolve(arg0);
        return ret;
    };
    imports.wbg.__wbg_resume_35efdc4ffe13bf18 = function () {
        return handleError(function (arg0) {
            const ret = arg0.resume();
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_revokeObjectURL_27267efebeb457c7 = function () {
        return handleError(function (arg0, arg1) {
            URL.revokeObjectURL(getStringFromWasm0(arg0, arg1));
        }, arguments);
    };
    imports.wbg.__wbg_samplerParameterf_909baf50360c94d4 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.samplerParameterf(arg1, arg2 >>> 0, arg3);
    };
    imports.wbg.__wbg_samplerParameteri_d5c292172718da63 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.samplerParameteri(arg1, arg2 >>> 0, arg3);
    };
    imports.wbg.__wbg_scheduler_3dbf209fc08aa5b8 = function (arg0) {
        const ret = arg0.scheduler;
        return ret;
    };
    imports.wbg.__wbg_scheduler_798ad2c2c23a54ad = function (arg0) {
        const ret = arg0.scheduler;
        return ret;
    };
    imports.wbg.__wbg_scissor_e917a332f67a5d30 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.scissor(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_scissor_eb177ca33bf24a44 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.scissor(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_setAttribute_2704501201f15687 = function () {
        return handleError(function (arg0, arg1, arg2, arg3, arg4) {
            arg0.setAttribute(
                getStringFromWasm0(arg1, arg2),
                getStringFromWasm0(arg3, arg4),
            );
        }, arguments);
    };
    imports.wbg.__wbg_setPointerCapture_c04dafaf4d00ffad = function () {
        return handleError(function (arg0, arg1) {
            arg0.setPointerCapture(arg1);
        }, arguments);
    };
    imports.wbg.__wbg_setProperty_f2cf326652b9a713 = function () {
        return handleError(function (arg0, arg1, arg2, arg3, arg4) {
            arg0.setProperty(
                getStringFromWasm0(arg1, arg2),
                getStringFromWasm0(arg3, arg4),
            );
        }, arguments);
    };
    imports.wbg.__wbg_setTimeout_461fec76662b35ea = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg0.setTimeout(arg1);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_setTimeout_f2fe5af8e3debeb3 = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = arg0.setTimeout(arg1, arg2);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_set_65595bdd868b3009 = function (arg0, arg1, arg2) {
        arg0.set(arg1, arg2 >>> 0);
    };
    imports.wbg.__wbg_set_bb8cecf6a62b9f46 = function () {
        return handleError(function (arg0, arg1, arg2) {
            const ret = Reflect.set(arg0, arg1, arg2);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_setbox_2786f3ccea97cac4 = function (arg0, arg1) {
        arg0.box = __wbindgen_enum_ResizeObserverBoxOptions[arg1];
    };
    imports.wbg.__wbg_setbuffer_10a9ee2a05c73896 = function (arg0, arg1) {
        arg0.buffer = arg1;
    };
    imports.wbg.__wbg_setchannelCount_876fcf5798895180 = function (arg0, arg1) {
        arg0.channelCount = arg1 >>> 0;
    };
    imports.wbg.__wbg_setcursor_bc821328a605fbbf = function (arg0, arg1, arg2) {
        arg0.cursor = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_setduration_f82387f93a69291a = function (arg0, arg1) {
        arg0.duration = arg1;
    };
    imports.wbg.__wbg_setheight_433680330c9420c3 = function (arg0, arg1) {
        arg0.height = arg1 >>> 0;
    };
    imports.wbg.__wbg_setheight_da683a33fa99843c = function (arg0, arg1) {
        arg0.height = arg1 >>> 0;
    };
    imports.wbg.__wbg_setiterations_e6e9dcd76cfef277 = function (arg0, arg1) {
        arg0.iterations = arg1;
    };
    imports.wbg.__wbg_setonended_00ff85c70a4f819f = function (arg0, arg1) {
        arg0.onended = arg1;
    };
    imports.wbg.__wbg_setonmessage_23d122da701b8ddb = function (arg0, arg1) {
        arg0.onmessage = arg1;
    };
    imports.wbg.__wbg_setpremultiplyalpha_fcd6afcf3903b790 = function (
        arg0,
        arg1,
    ) {
        arg0.premultiplyAlpha = __wbindgen_enum_PremultiplyAlpha[arg1];
    };
    imports.wbg.__wbg_setsamplerate_8bc3fd769a6db02b = function (arg0, arg1) {
        arg0.sampleRate = arg1;
    };
    imports.wbg.__wbg_setsrc_c239193cc7ab0470 = function (arg0, arg1, arg2) {
        arg0.src = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_settype_39ed370d3edd403c = function (arg0, arg1, arg2) {
        arg0.type = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_setwidth_660ca581e3fbe279 = function (arg0, arg1) {
        arg0.width = arg1 >>> 0;
    };
    imports.wbg.__wbg_setwidth_c5fed9f5e7f0b406 = function (arg0, arg1) {
        arg0.width = arg1 >>> 0;
    };
    imports.wbg.__wbg_shaderSource_72d3e8597ef85b67 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.shaderSource(arg1, getStringFromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_shaderSource_ad0087e637a35191 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.shaderSource(arg1, getStringFromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_shiftKey_2bebb3b703254f47 = function (arg0) {
        const ret = arg0.shiftKey;
        return ret;
    };
    imports.wbg.__wbg_shiftKey_86e737105bab1a54 = function (arg0) {
        const ret = arg0.shiftKey;
        return ret;
    };
    imports.wbg.__wbg_signal_aaf9ad74119f20a4 = function (arg0) {
        const ret = arg0.signal;
        return ret;
    };
    imports.wbg.__wbg_stack_0ed75d68575b0f3c = function (arg0, arg1) {
        const ret = arg1.stack;
        const ptr1 = passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
        );
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_start_2c099369ce831bf1 = function (arg0) {
        arg0.start();
    };
    imports.wbg.__wbg_start_e81f89e130c3c86e = function () {
        return handleError(function (arg0, arg1) {
            arg0.start(arg1);
        }, arguments);
    };
    imports.wbg.__wbg_static_accessor_GLOBAL_88a902d13a557d07 = function () {
        const ret = typeof global === 'undefined' ? null : global;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_static_accessor_GLOBAL_THIS_56578be7e9f832b0 =
        function () {
            const ret = typeof globalThis === 'undefined' ? null : globalThis;
            return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
        };
    imports.wbg.__wbg_static_accessor_SELF_37c5d418e4bf5819 = function () {
        const ret = typeof self === 'undefined' ? null : self;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_static_accessor_WINDOW_5de37043a91a9c40 = function () {
        const ret = typeof window === 'undefined' ? null : window;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_status_f6360336ca686bf0 = function (arg0) {
        const ret = arg0.status;
        return ret;
    };
    imports.wbg.__wbg_stencilFuncSeparate_91700dcf367ae07e = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.stencilFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3, arg4 >>> 0);
    };
    imports.wbg.__wbg_stencilFuncSeparate_c1a6fa2005ca0aaf = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.stencilFuncSeparate(arg1 >>> 0, arg2 >>> 0, arg3, arg4 >>> 0);
    };
    imports.wbg.__wbg_stencilMaskSeparate_4f1a2defc8c10956 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.stencilMaskSeparate(arg1 >>> 0, arg2 >>> 0);
    };
    imports.wbg.__wbg_stencilMaskSeparate_f8a0cfb5c2994d4a = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.stencilMaskSeparate(arg1 >>> 0, arg2 >>> 0);
    };
    imports.wbg.__wbg_stencilMask_1e602ef63f5b4144 = function (arg0, arg1) {
        arg0.stencilMask(arg1 >>> 0);
    };
    imports.wbg.__wbg_stencilMask_cd8ca0a55817e599 = function (arg0, arg1) {
        arg0.stencilMask(arg1 >>> 0);
    };
    imports.wbg.__wbg_stencilOpSeparate_1fa08985e79e1627 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.stencilOpSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
    };
    imports.wbg.__wbg_stencilOpSeparate_ff6683bbe3838ae6 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.stencilOpSeparate(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, arg4 >>> 0);
    };
    imports.wbg.__wbg_stringify_f7ed6987935b4a24 = function () {
        return handleError(function (arg0) {
            const ret = JSON.stringify(arg0);
            return ret;
        }, arguments);
    };
    imports.wbg.__wbg_style_fb30c14e5815805c = function (arg0) {
        const ret = arg0.style;
        return ret;
    };
    imports.wbg.__wbg_subarray_aa9065fa9dc5df96 = function (arg0, arg1, arg2) {
        const ret = arg0.subarray(arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_texImage2D_5f2835f02b1d1077 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texImage2D_b8edcb5692f65f88 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texImage3D_921b54d09bf45af0 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
            arg10,
        ) {
            arg0.texImage3D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7,
                arg8 >>> 0,
                arg9 >>> 0,
                arg10,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texParameteri_8112b26b3c360b7e = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
    };
    imports.wbg.__wbg_texParameteri_ef50743cb94d507e = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
    };
    imports.wbg.__wbg_texStorage2D_fbda848497f3674e = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.texStorage2D(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
    };
    imports.wbg.__wbg_texStorage3D_fd7a7ca30e7981d1 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
    ) {
        arg0.texStorage3D(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5, arg6);
    };
    imports.wbg.__wbg_texSubImage2D_061605071aad9d2c = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texSubImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage2D_aa9a084093764796 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texSubImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage2D_c7951ed97252bdff = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texSubImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage2D_d52d1a0d3654c60b = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texSubImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage2D_dd9cac68ad5fe0b6 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texSubImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage2D_e6d34f5bb062e404 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texSubImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage2D_f39ea52a2d4bd2f7 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texSubImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage2D_fbdf91268228c757 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
        ) {
            arg0.texSubImage2D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7 >>> 0,
                arg8 >>> 0,
                arg9,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage3D_04731251d7cecc83 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
            arg10,
            arg11,
        ) {
            arg0.texSubImage3D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7,
                arg8,
                arg9 >>> 0,
                arg10 >>> 0,
                arg11,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage3D_37f0045d16871670 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
            arg10,
            arg11,
        ) {
            arg0.texSubImage3D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7,
                arg8,
                arg9 >>> 0,
                arg10 >>> 0,
                arg11,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage3D_3a871f6405d2f183 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
            arg10,
            arg11,
        ) {
            arg0.texSubImage3D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7,
                arg8,
                arg9 >>> 0,
                arg10 >>> 0,
                arg11,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage3D_66acd67f56e3b214 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
            arg10,
            arg11,
        ) {
            arg0.texSubImage3D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7,
                arg8,
                arg9 >>> 0,
                arg10 >>> 0,
                arg11,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage3D_a051de089266fa1b = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
            arg10,
            arg11,
        ) {
            arg0.texSubImage3D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7,
                arg8,
                arg9 >>> 0,
                arg10 >>> 0,
                arg11,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage3D_b28c55f839bbec41 = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
            arg10,
            arg11,
        ) {
            arg0.texSubImage3D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7,
                arg8,
                arg9 >>> 0,
                arg10 >>> 0,
                arg11,
            );
        }, arguments);
    };
    imports.wbg.__wbg_texSubImage3D_f18bf091cd48774c = function () {
        return handleError(function (
            arg0,
            arg1,
            arg2,
            arg3,
            arg4,
            arg5,
            arg6,
            arg7,
            arg8,
            arg9,
            arg10,
            arg11,
        ) {
            arg0.texSubImage3D(
                arg1 >>> 0,
                arg2,
                arg3,
                arg4,
                arg5,
                arg6,
                arg7,
                arg8,
                arg9 >>> 0,
                arg10 >>> 0,
                arg11,
            );
        }, arguments);
    };
    imports.wbg.__wbg_then_44b73946d2fb3e7d = function (arg0, arg1) {
        const ret = arg0.then(arg1);
        return ret;
    };
    imports.wbg.__wbg_then_48b406749878a531 = function (arg0, arg1, arg2) {
        const ret = arg0.then(arg1, arg2);
        return ret;
    };
    imports.wbg.__wbg_toBlob_b9c8aa2351e3ea86 = function () {
        return handleError(function (arg0, arg1) {
            arg0.toBlob(arg1);
        }, arguments);
    };
    imports.wbg.__wbg_transferFromImageBitmap_79b631bb9a257746 = function (
        arg0,
        arg1,
    ) {
        arg0.transferFromImageBitmap(arg1);
    };
    imports.wbg.__wbg_uniform1f_21390b04609a9fa5 = function (arg0, arg1, arg2) {
        arg0.uniform1f(arg1, arg2);
    };
    imports.wbg.__wbg_uniform1f_dc009a0e7f7e5977 = function (arg0, arg1, arg2) {
        arg0.uniform1f(arg1, arg2);
    };
    imports.wbg.__wbg_uniform1i_5ddd9d8ccbd390bb = function (arg0, arg1, arg2) {
        arg0.uniform1i(arg1, arg2);
    };
    imports.wbg.__wbg_uniform1i_ed95b6129dce4d84 = function (arg0, arg1, arg2) {
        arg0.uniform1i(arg1, arg2);
    };
    imports.wbg.__wbg_uniform1ui_66e092b67a21c84d = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.uniform1ui(arg1, arg2 >>> 0);
    };
    imports.wbg.__wbg_uniform2fv_656fce9525420996 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform2fv(arg1, getArrayF32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform2fv_d8bd2a36da7ce440 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform2fv(arg1, getArrayF32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform2iv_4d39fc5a26f03f55 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform2iv(arg1, getArrayI32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform2iv_e967139a28017a99 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform2iv(arg1, getArrayI32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform2uiv_4c340c9e8477bb07 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform2uiv(arg1, getArrayU32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform3fv_7d828b7c4c91138e = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform3fv(arg1, getArrayF32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform3fv_8153c834ce667125 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform3fv(arg1, getArrayF32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform3iv_58662d914661aa10 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform3iv(arg1, getArrayI32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform3iv_f30d27ec224b4b24 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform3iv(arg1, getArrayI32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform3uiv_38673b825dc755f6 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform3uiv(arg1, getArrayU32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform4f_36b8f9be15064aa7 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.uniform4f(arg1, arg2, arg3, arg4, arg5);
    };
    imports.wbg.__wbg_uniform4f_f7ea07febf8b5108 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.uniform4f(arg1, arg2, arg3, arg4, arg5);
    };
    imports.wbg.__wbg_uniform4fv_8827081a7585145b = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform4fv(arg1, getArrayF32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform4fv_c01fbc6c022abac3 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform4fv(arg1, getArrayF32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform4iv_7fe05be291899f06 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform4iv(arg1, getArrayI32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform4iv_84fdf80745e7ff26 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform4iv(arg1, getArrayI32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniform4uiv_9de55998fbfef236 = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniform4uiv(arg1, getArrayU32FromWasm0(arg2, arg3));
    };
    imports.wbg.__wbg_uniformBlockBinding_18117f4bda07115b = function (
        arg0,
        arg1,
        arg2,
        arg3,
    ) {
        arg0.uniformBlockBinding(arg1, arg2 >>> 0, arg3 >>> 0);
    };
    imports.wbg.__wbg_uniformMatrix2fv_98681e400347369c = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix2fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix2fv_bc019eb4784a3b8c = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix2fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix2x3fv_6421f8d6f7f4d144 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix2x3fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix2x4fv_27d807767d7aadc6 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix2x4fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix3fv_3d6ad3a1e0b0b5b6 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix3fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix3fv_3df529aab93cf902 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix3fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix3x2fv_79357317e9637d05 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix3x2fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix3x4fv_9d1a88b5abfbd64b = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix3x4fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix4fv_da94083874f202ad = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix4fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix4fv_e87383507ae75670 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix4fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix4x2fv_aa507d918a0b5a62 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix4x2fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_uniformMatrix4x3fv_6712c7a3b4276fb4 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.uniformMatrix4x3fv(
            arg1,
            arg2 !== 0,
            getArrayF32FromWasm0(arg3, arg4),
        );
    };
    imports.wbg.__wbg_unobserve_02f53d1ca2d1d801 = function (arg0, arg1) {
        arg0.unobserve(arg1);
    };
    imports.wbg.__wbg_useProgram_473bf913989b6089 = function (arg0, arg1) {
        arg0.useProgram(arg1);
    };
    imports.wbg.__wbg_useProgram_9b2660f7bb210471 = function (arg0, arg1) {
        arg0.useProgram(arg1);
    };
    imports.wbg.__wbg_userAgentData_0ba21969d1f27636 = function (arg0) {
        const ret = arg0.userAgentData;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_userAgent_12e9d8e62297563f = function () {
        return handleError(function (arg0, arg1) {
            const ret = arg1.userAgent;
            const ptr1 = passStringToWasm0(
                ret,
                wasm.__wbindgen_malloc,
                wasm.__wbindgen_realloc,
            );
            const len1 = WASM_VECTOR_LEN;
            getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
            getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
        }, arguments);
    };
    imports.wbg.__wbg_value_f992786d714424bd = function (arg0) {
        const ret = arg0.value;
        return ret;
    };
    imports.wbg.__wbg_versions_c71aa1626a93e0a1 = function (arg0) {
        const ret = arg0.versions;
        return ret;
    };
    imports.wbg.__wbg_vertexAttribDivisorANGLE_11e909d332960413 = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.vertexAttribDivisorANGLE(arg1 >>> 0, arg2 >>> 0);
    };
    imports.wbg.__wbg_vertexAttribDivisor_4d361d77ffb6d3ff = function (
        arg0,
        arg1,
        arg2,
    ) {
        arg0.vertexAttribDivisor(arg1 >>> 0, arg2 >>> 0);
    };
    imports.wbg.__wbg_vertexAttribIPointer_d0c67543348c90ce = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
    ) {
        arg0.vertexAttribIPointer(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
    };
    imports.wbg.__wbg_vertexAttribPointer_550dc34903e3d1ea = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
    ) {
        arg0.vertexAttribPointer(
            arg1 >>> 0,
            arg2,
            arg3 >>> 0,
            arg4 !== 0,
            arg5,
            arg6,
        );
    };
    imports.wbg.__wbg_vertexAttribPointer_7a2a506cdbe3aebc = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
        arg5,
        arg6,
    ) {
        arg0.vertexAttribPointer(
            arg1 >>> 0,
            arg2,
            arg3 >>> 0,
            arg4 !== 0,
            arg5,
            arg6,
        );
    };
    imports.wbg.__wbg_videoHeight_3a43327a766c1f03 = function (arg0) {
        const ret = arg0.videoHeight;
        return ret;
    };
    imports.wbg.__wbg_videoWidth_4b400cf6f4744a4d = function (arg0) {
        const ret = arg0.videoWidth;
        return ret;
    };
    imports.wbg.__wbg_viewport_a1b4d71297ba89af = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.viewport(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_viewport_e615e98f676f2d39 = function (
        arg0,
        arg1,
        arg2,
        arg3,
        arg4,
    ) {
        arg0.viewport(arg1, arg2, arg3, arg4);
    };
    imports.wbg.__wbg_visibilityState_f3cc18a6f3831137 = function (arg0) {
        const ret = arg0.visibilityState;
        return (__wbindgen_enum_VisibilityState.indexOf(ret) + 1 || 3) - 1;
    };
    imports.wbg.__wbg_webkitExitFullscreen_dcb418d2357698e2 = function (arg0) {
        arg0.webkitExitFullscreen();
    };
    imports.wbg.__wbg_webkitFullscreenElement_1705b28e33fad768 = function (
        arg0,
    ) {
        const ret = arg0.webkitFullscreenElement;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_webkitRequestFullscreen_416bc13a99f0f3ed = function (
        arg0,
    ) {
        arg0.webkitRequestFullscreen();
    };
    imports.wbg.__wbg_width_4f334fc47ef03de1 = function (arg0) {
        const ret = arg0.width;
        return ret;
    };
    imports.wbg.__wbg_width_5dde457d606ba683 = function (arg0) {
        const ret = arg0.width;
        return ret;
    };
    imports.wbg.__wbg_width_8fe4e8f77479c2a6 = function (arg0) {
        const ret = arg0.width;
        return ret;
    };
    imports.wbg.__wbg_width_b0c1d9f437a95799 = function (arg0) {
        const ret = arg0.width;
        return ret;
    };
    imports.wbg.__wbg_width_cdaf02311c1621d1 = function (arg0) {
        const ret = arg0.width;
        return ret;
    };
    imports.wbg.__wbg_width_f54c7178d3c78f16 = function (arg0) {
        const ret = arg0.width;
        return ret;
    };
    imports.wbg.__wbg_x_2bc3f61e11d9f2e1 = function (arg0) {
        const ret = arg0.x;
        return ret;
    };
    imports.wbg.__wbg_y_be10a4f665290032 = function (arg0) {
        const ret = arg0.y;
        return ret;
    };
    imports.wbg.__wbindgen_boolean_get = function (arg0) {
        const v = arg0;
        const ret = typeof v === 'boolean' ? (v ? 1 : 0) : 2;
        return ret;
    };
    imports.wbg.__wbindgen_cb_drop = function (arg0) {
        const obj = arg0.original;
        if (obj.cnt-- == 1) {
            obj.a = 0;
            return true;
        }
        const ret = false;
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2590 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_36);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2592 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_36);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2594 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_36);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2596 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_36);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2598 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_36);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2600 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_36);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2602 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_49);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2604 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_36);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2606 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_36);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2608 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_56);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper2610 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 1661, __wbg_adapter_59);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper39861 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 30653, __wbg_adapter_62);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper75645 = function (arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 56824, __wbg_adapter_65);
        return ret;
    };
    imports.wbg.__wbindgen_debug_string = function (arg0, arg1) {
        const ret = debugString(arg1);
        const ptr1 = passStringToWasm0(
            ret,
            wasm.__wbindgen_malloc,
            wasm.__wbindgen_realloc,
        );
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbindgen_init_externref_table = function () {
        const table = wasm.__wbindgen_export_1;
        const offset = table.grow(4);
        table.set(0, undefined);
        table.set(offset + 0, undefined);
        table.set(offset + 1, null);
        table.set(offset + 2, true);
        table.set(offset + 3, false);
    };
    imports.wbg.__wbindgen_is_function = function (arg0) {
        const ret = typeof arg0 === 'function';
        return ret;
    };
    imports.wbg.__wbindgen_is_null = function (arg0) {
        const ret = arg0 === null;
        return ret;
    };
    imports.wbg.__wbindgen_is_object = function (arg0) {
        const val = arg0;
        const ret = typeof val === 'object' && val !== null;
        return ret;
    };
    imports.wbg.__wbindgen_is_string = function (arg0) {
        const ret = typeof arg0 === 'string';
        return ret;
    };
    imports.wbg.__wbindgen_is_undefined = function (arg0) {
        const ret = arg0 === undefined;
        return ret;
    };
    imports.wbg.__wbindgen_memory = function () {
        const ret = wasm.memory;
        return ret;
    };
    imports.wbg.__wbindgen_number_get = function (arg0, arg1) {
        const obj = arg1;
        const ret = typeof obj === 'number' ? obj : undefined;
        getDataViewMemory0().setFloat64(
            arg0 + 8 * 1,
            isLikeNone(ret) ? 0 : ret,
            true,
        );
        getDataViewMemory0().setInt32(arg0 + 4 * 0, !isLikeNone(ret), true);
    };
    imports.wbg.__wbindgen_number_new = function (arg0) {
        const ret = arg0;
        return ret;
    };
    imports.wbg.__wbindgen_string_get = function (arg0, arg1) {
        const obj = arg1;
        const ret = typeof obj === 'string' ? obj : undefined;
        var ptr1 = isLikeNone(ret)
            ? 0
            : passStringToWasm0(
                  ret,
                  wasm.__wbindgen_malloc,
                  wasm.__wbindgen_realloc,
              );
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbindgen_string_new = function (arg0, arg1) {
        const ret = getStringFromWasm0(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbindgen_throw = function (arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };

    return imports;
}

function __wbg_init_memory(imports, memory) {}

function __wbg_finalize_init(instance, module) {
    wasm = instance.exports;
    __wbg_init.__wbindgen_wasm_module = module;
    cachedDataViewMemory0 = null;
    cachedFloat32ArrayMemory0 = null;
    cachedInt32ArrayMemory0 = null;
    cachedUint32ArrayMemory0 = null;
    cachedUint8ArrayMemory0 = null;
    cachedUint8ClampedArrayMemory0 = null;

    wasm.__wbindgen_start();
    return wasm;
}

function initSync(module) {
    if (wasm !== undefined) return wasm;

    if (typeof module !== 'undefined') {
        if (Object.getPrototypeOf(module) === Object.prototype) {
            ({ module } = module);
        } else {
            console.warn(
                'using deprecated parameters for `initSync()`; pass a single object instead',
            );
        }
    }

    const imports = __wbg_get_imports();

    __wbg_init_memory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return __wbg_finalize_init(instance, module);
}

async function __wbg_init(module_or_path) {
    if (wasm !== undefined) return wasm;

    if (typeof module_or_path !== 'undefined') {
        if (Object.getPrototypeOf(module_or_path) === Object.prototype) {
            ({ module_or_path } = module_or_path);
        } else {
            console.warn(
                'using deprecated parameters for the initialization function; pass a single object instead',
            );
        }
    }

    if (typeof module_or_path === 'undefined') {
        module_or_path = new URL('utils_wasm_lib_bg.wasm', import.meta.url);
    }
    const imports = __wbg_get_imports();

    if (
        typeof module_or_path === 'string' ||
        (typeof Request === 'function' && module_or_path instanceof Request) ||
        (typeof URL === 'function' && module_or_path instanceof URL)
    ) {
        module_or_path = fetch(module_or_path);
    }

    __wbg_init_memory(imports);

    const { instance, module } = await __wbg_load(
        await module_or_path,
        imports,
    );

    return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;
